import React from 'react';
import DisplayReportTable from './DisplayReportTable';
import {REPORT_DISPLAY_TYPES} from '../../../../constants/report';
import {Report} from '../../../../constants/propTypesDefinitions';

/**
 * @fero
 */


class DisplayReport extends React.PureComponent {
    static propTypes = {
        report: Report.isRequired,
    };

    render() {
        const {report} = this.props;
        const reportDisplayTypeType = report != null && report.args != null ? report.args.display_type : null;

        switch(reportDisplayTypeType) {
            default: return <DisplayReportTable
                report={report}
            />
        }
    }

}

export default DisplayReport;