import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../general/Table';
import {Report} from '../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class DisplayReportTable extends React.PureComponent {
    static propTypes = {
        report: Report.isRequired,
    };

    render() {
        const {report} = this.props;
        const reportChecked = report != null ? report : null;
        const columns = reportChecked.columns;
        const data = reportChecked.data;
        const columnsChecked = columns != null && columns.length != null ? columns : [];
        const dataChecked = data != null && data.length != null ? data : [];
        return <Table
            BodyRow={DisplayReportTableRow}
            data={dataChecked}
            reload={() => {console.warn('Reload called on DiplayReportTable which is not defined.')}}
            colDefs={columnsChecked.map((column, index) => {
                return {
                    headerCell: column,
                }
            })}
            columnKeys={columns}
            footer={reportChecked.next_page_id != null ? {
                heightRem: 2.5,
                node: <h3><Trans>Report je príliš dlhý na zobrazenie. Použite voľbu "Stiahnuť".</Trans></h3> 
            }: null}
        />;
    }

}

export default DisplayReportTable;

class DisplayReportTableRow extends React.PureComponent {
    static propTypes = {
        data: PropTypes.object.isRequired,
        columnKeys: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    };

    render() {
        const {columnKeys, data} = this.props;
        return <tr>
            {columnKeys.map((column, index) => {
                return <td key={index}>
                    {data[column]}
                </td>
            })}
        </tr>
    }
}