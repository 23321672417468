import React from 'react';
import PropTypes from 'prop-types';
import DisplayReport from './DisplayReport';
import withDataHOC from '../../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../../constants/globalData';
import {createFetchReportRun} from '../../../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class DisplayReportWrapper extends React.PureComponent {
    static propTypes = {
        reportId: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            report: null,
        }
    }

    componentDidMount() {
        const {reportId} = this.props;
        if (reportId != null) {
            this.fetchReportOutput();
        }
    }

    componentDidUpdate(prevProps) {
        const {reportId} = this.props;
        const {reportId: prevReportId} = prevProps;
        const {report} = this.state;
        if (reportId != null && (reportId != prevReportId || report == null)) {
            this.fetchReportOutput();
        }
    }

    fetchReportOutput = () => {
        const {reportId} = this.props;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchReportRun(),
            reportId,
            (result) => {
                this.setState({
                    report: result,
                })
            }
        )
    };

    render() {
        const {reportId} = this.props;
        const {report} = this.state;
        if (report != null) {
            return <DisplayReport
                report={report}
            />;
        } else if(reportId == null){
            return <div>
                <h2 className="m-3 color-disabled text-center">
                    <Trans>Vyberte report pre zobrazenie.</Trans>
                </h2>
            </div>;
        }
        else
            return null;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(DisplayReportWrapper);