import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import { getQueryParamNumber, navigateToParametrized} from '../../lib/url';
import { ReportDefinitions } from '../../constants/propTypesDefinitions';
import PageActionsLayout from '../general/PageActionsLayout';
import {Trans, t} from '@lingui/macro'
import ExportReportButton from './report/reportList/ExportReportButton';
import RemoveReportButton from './report/reportList/RemoveReportButton';
import EditReportButton from './report/reportList/EditReportButton';
import DisplayReportWrapper from './report/displayReports/DisplayReportWrapper';
import { RIGHTS } from '../../constants/Rights';

/**
 * @fero
 */

class DisplayReportPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.REPORTS]: ReportDefinitions.isRequired,
        location: PropTypes.object.isRequired,
    };

    
    getCurrentReport = () => {
        const {[GLOBAL_DATA.REPORTS]: reports, location} = this.props;
        const reportId = getQueryParamNumber(location, QUERY_PARAMS.ID_REPORT);
        return reports.find(report => report.id == reportId);
    }

    onRemove = () => {
        const {location} = this.props;
        navigateToParametrized(location, ROUTES.REPORTS, {[QUERY_PARAMS.ID_REPORT]: undefined});
    }

    render() {
        const currentReport = this.getCurrentReport();
        return <React.Fragment>
            <Helmet
                title={currentReport ? currentReport.name : null}
            />
            <div className="px-3 full-size-height full-size-width overflow-y-auto">
                <PageActionsLayout
                    back={{
                        to: ROUTES.REPORTS,
                        title: <Trans>Späť</Trans>,
                    }}
                    actions={currentReport != null ? [
                        {
                            node: <EditReportButton
                                reportId={currentReport.id}
                            />,
                            rightsFrom: RIGHTS.MANAGER
                        },
                        {
                            node: <RemoveReportButton
                                reportId={currentReport.id}
                                onFinish={this.onRemove}
                            />,
                            rightsFrom: RIGHTS.MANAGER
                        },
                        {
                            node: <ExportReportButton
                                reportId={currentReport.id}
                            />
                        },
                    ] : null}
                />
                { currentReport != null ?
                    <h2 className="text-center">{currentReport.name}</h2> :
                    null
                }
                <DisplayReportWrapper
                    reportId={currentReport != null ? currentReport.id : null}
                />
            </div>
        </React.Fragment>;
    }
}

export default locationHOC(withDataHOC([GLOBAL_DATA.REPORTS])(DisplayReportPage));